import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import GalleryModal from './GalleryModal';
import useScrollEvent from '../components/useScrollEvent';

import "./_gallery.scss";

let int;

export default function Gallery() {
  const [index, setIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [galleryStyle, setGalleryStyle] = useState();
  const [modal, setData, setModalIsOpen, clostBtn] = GalleryModal();
  useScrollEvent({
    elmArray: [
      {
        cls: 'gallery',
        stat: galleryStyle,
        fun: () =>
          setGalleryStyle({ opacity: '1' })
      }
    ],
    // dependence: [galleryStyle],
    isExec: !galleryStyle
  });

  useEffect(() => {
    const renewWidth = () => {
      if (window.innerWidth >= 960 && screenWidth < 960)
        setScreenWidth(window.innerWidth)
      else if (window.innerWidth < 960 && screenWidth >= 960) {
        setIndex(0);
        setScreenWidth(window.innerWidth);
      }
    }

    window.addEventListener('resize', renewWidth);

    return () => {
      window.removeEventListener('resize', renewWidth);
    }
  }, [screenWidth]);

  useEffect(() => {
    const carousel = () => {
      const gallerys = document.getElementsByClassName("gallery__item");

      if (gallerys.length === 0)
        return;

      if (index + 1 === gallerys.length)
        setIndex(0)
      else
        setIndex(index + 1);
    }

    if (window.innerWidth < 960)
      int = setTimeout(carousel, 5000)
    else {
      const gallerys = document.getElementsByClassName("gallery__item");
      if (gallerys.length !== 0) {
        for (let i = 0; i < gallerys.length; i++) {
          gallerys[i].style.opacity = "1";
        }
      }
    }

    return () => {
      clearTimeout(int);
    }
  }, [index]);

  const imgList = [];
  const showImg = i => {
    const width = window.innerWidth;
    if (width < 960)
      return;

    setData(imgList[i - 1]);
    setModalIsOpen(true);
  }

  for (let i = 1; i <= 17; i++) {
    imgList.push(
      <LazyLoad
        offset={200}
        key={i}
      >
        <figure
          className={`gallery__item gallery__item--${i}`}
          style={{
            opacity: (index === i - 1 || screenWidth >= 960) ? '1' : '0',
            transition: "opacity 1s"
          }}
        >
          <img
            src={require(`../assets/img/imggal-${i}.jpg`)}
            aria-hidden
            alt={`Gallery image ${i}`}
            className="gallery__img"
            onClick={() => showImg(i)}
          >
          </img>
          {clostBtn}
        </figure>
      </LazyLoad>
    )
  };

  return (
    <section
      className="gallery"
      style={galleryStyle}
    >
      {imgList}
      {modal}
    </section>
  );
}
