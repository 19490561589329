import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';

import useScrollEvent from '../components/useScrollEvent';
import Description from "../Description/Description";
import coverAboutStory from './coverAboutStory';

import datas from '../assets/data/aboutData.json';
import svgUrl from "../assets/img/sprite.svg";
import aboutline from '../assets/img/aboutline.png';

import "./_aboutMain.scss";

let int;

export default function AboutMain() {
  const [textIndex, setTextIndex] = useState(0);
  const [storyIndex, setStoryIndex] = useState(0);
  const [touchStartPosition, setTouchStartPosition] = useState({ x: '', y: '' });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  //descirption, text, story style are for lazyload
  const [descriptionStyle, setDescriptionStyle] = useState();
  const [textStyle, setTextStyle] = useState();
  const [storyStyle, setStoryStyle] = useState();
  useScrollEvent({
    elmArray: [
      {
        cls: 'about__top__description__title',
        stat: descriptionStyle,
        fun: () =>
          setDescriptionStyle({ opacity: '1', top: (window.innerWidth > 960) ? '50%' : '0' })
      },
      {
        cls: 'about__top__text',
        stat: textStyle,
        fun: () =>
          setTextStyle({ opacity: '1', top: '0' })
      },
      {
        cls: 'about__story',
        stat: storyStyle,
        fun: () =>
          setStoryStyle({ opacity: '1', top: '0' })
      }
    ],
    // dependence: [descriptionStyle, textStyle, storyStyle],
    isExec: !descriptionStyle || !textStyle || !storyStyle
  });

  useEffect(() => {
    const renewWidth = () => {
      if (window.innerWidth >= 960 && screenWidth < 960) {
        setScreenWidth(window.innerWidth);
        setDescriptionStyle({
          opacity: '1',
          top: '50%'
        })
      }
      else if (window.innerWidth < 960 && screenWidth >= 960) {
        setScreenWidth(window.innerWidth);
        setDescriptionStyle({
          opacity: '1',
          top: '0'
        })
      }
    }

    window.addEventListener('resize', renewWidth);

    return () => {
      window.removeEventListener('resize', renewWidth);
    }
  }, [screenWidth]);

  useEffect(() => {
    function carousel() {
      const texts = document.getElementsByClassName("about__top__text--item");
      if (texts.length === 0)
        return;

      if (textIndex + 1 === texts.length)
        setTextIndex(0)
      else
        setTextIndex(textIndex + 1);
    }

    if (screenWidth < 960)
      int = setTimeout(carousel, 5000)
    else {
      const texts = document.getElementsByClassName("about__top__text--item");
      if (texts.length !== 0) {
        for (let i = 0; i < texts.length; i++) {
          texts[i].style.opacity = "1";
        }
      }
    }

    return () => {
      clearTimeout(int);
    }
  }, [textIndex, screenWidth]);

  const Icon = props => (
    <svg
      className={props.class}
      onClick={props.click}
    >
      <use xlinkHref={`${svgUrl}#lnr-${props.icon}`} />
    </svg>
  )

  const increment = () => {
    if (storyIndex + 1 === aboutStoryList.length)
      setStoryIndex(0)
    else
      setStoryIndex(storyIndex + 1);
  }

  const decrement = () => {
    if (storyIndex === 0)
      setStoryIndex(aboutStoryList.length - 1)
    else
      setStoryIndex(storyIndex - 1);
  }

  const touchStart = e => {
    e.preventDefault();
    const { clientX, clientY } = e.touches[0];
    setTouchStartPosition({
      x: clientX,
      y: clientY
    });
  }

  const touchEnd = e => {
    e.preventDefault();
    const deltaX = e.changedTouches[0].clientX - touchStartPosition.x;
    const deltaY = e.changedTouches[0].clientY - touchStartPosition.y;
    if (deltaX > 50 && deltaY < deltaX && deltaY < 30)
      decrement()
    else if (deltaX < -50 && deltaY < -deltaX && deltaY < 30)
      increment();
  }

  const aboutStoryList = coverAboutStory({
    datas: datas.df,
    touchStart: touchStart,
    touchEnd: touchEnd
  });

  const stopCount = idx => {
    clearTimeout(int);
    setTextIndex(idx);
  }

  return (
    <section id="about" className="about">
      <div className="about__top">
        <LazyLoad>
          <div
            className="about__top__description"
            style={{
              backgroundImage: `linear-gradient(
                  to right bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)),
              url(${require(`../assets/img/about-0.jpg`)})`,
              backgroundSize: '1444px 705px',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="about__top__description__title" style={descriptionStyle}>
              <div className="heading-2 heading-2--light">
                關於久泰
            </div>
              <Description />
            </div>
          </div>
        </LazyLoad>

        <div className="about__top__text" style={textStyle}>
          <div
            className="about__top__text--item"
            style={{
              opacity: (textIndex === 0 || screenWidth >= 960) ? '1' : '0',
              transition: "opacity 1s"
            }}
          >
            <h4 className="about__top__text--subtitle heading-3 heading-3--light">
              堅持
            </h4>
            <h4 className="about__top__text--engtitle heading-7">
              PERSISENCE
            </h4>
            <p className="about__top__text--content heading-6 heading-5--light">
              面對每一次的挑戰<br />不斷的自我突破、提升
            </p>
          </div>
          <img
            src={aboutline}
            alt='this is line'
            className="about__top__text--line"
          >
          </img>
          <div
            className="about__top__text--item"
            style={{
              opacity: (textIndex === 1 || screenWidth >= 960) ? '1' : '0',
              transition: "opacity 1s"
            }}
          >
            <h4 className="about__topt__text--subtitle heading-3 heading-3--light">
              誠信
            </h4>
            <h4 className="about__top__text--engtitle heading-7">
              INTGRITY
            </h4>
            <p className="about__top__text--content heading-6 heading-5--light">
              嚴謹精良的建設品質<br />如同誠信對於人的重要
            </p>
          </div>
          <img
            src={aboutline}
            alt='this is line'
            className="about__top__text--line"
          >
          </img>
          <div
            className="about__top__text--item"
            style={{
              opacity: (textIndex === 2 || screenWidth >= 960) ? '1' : '0',
              transition: "opacity 1s"
            }}
          >
            <h4 className="about__top__text--subtitle heading-3 heading-3--light">
              肯定
            </h4>
            <h4 className="about__top__text--engtitle heading-7">
              DEFINITE
            </h4>
            <p className="about__top__text--content heading-6 heading-5--light">
              建立出穩固且極美的建築<br />讓細節展現出極致的結構美學
            </p>
          </div>
          <div className="about__top__text--circle">
            <div
              className="about__top__text--circle--1 circles"
              style={{
                backgroundColor: (textIndex === 0) ? '#000' : '#4e4e4e',
                transition: "background-color 1s"
              }}
              onClick={() => stopCount(0)}
            >

            </div>
            <div
              className="about__top__text--circle--2 circles"
              style={{
                backgroundColor: (textIndex === 1) ? '#000' : '#4e4e4e',
                transition: "background-color 1s"
              }}
              onClick={() => stopCount(1)}
            >

            </div>
            <div
              className="about__top__text--circle--3 circles"
              style={{
                backgroundColor: (textIndex === 2) ? '#000' : '#4e4e4e',
                transition: "background-color 1s"
              }}
              onClick={() => stopCount(2)}
            >
            </div>
          </div>
        </div>
      </div>
      <div className="about__story" style={storyStyle}>
        <Icon
          icon='chevron-left'
          class="about__story__btn left"
          click={decrement}
        />
        <LazyLoad>
          {aboutStoryList[storyIndex]}
        </LazyLoad>
        <Icon
          icon='chevron-right'
          class="about__story__btn right"
          click={increment}
        />
      </div>

    </section>
  );
}
