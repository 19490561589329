import React from 'react';

export default function coverAboutStory(props) {
  const dataDOMList = props.datas.map(data =>
    <div
      className="about__story--item"
      onTouchStart={props.touchStart}
      onTouchEnd={props.touchEnd}
    >
      <img
        src={require(`../assets/img/${data.img}.jpg`)}
        className="about__story--item__img"
        alt={data.alt}
      >
      </img>
      <div className="about__story--item__text">
        <h4 className="about__story--item__text--title heading-4">
          {data.title}
        </h4>
        <p className="about__story--item__text--content heading-6">
          {data.content}
        </p>
      </div>
    </div>
  );

  return dataDOMList;
};
