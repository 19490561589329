import React from 'react';
import { Link } from 'react-router-dom';

export default function coverNews(i, data, showData, fontNumber) {
  return (
    <div
      key={i}
      className="news__list__item"
    >
      <h3
        className="heading-6 heading-5--dark"
      >
        >>{data.date}
      </h3>
      <h4
        className="news__list__item__title heading-4 heading-4--dark"
      >
        {data.title}
      </h4>
      <h5 className="news__list__item__content heading-6 heading-5--dark">
        {data.content.substring(0, fontNumber).replace(/>/g, '\n') + '...'}
        <Link to={`news/${data.date.replace(/-/g, '/')}`}>
          <button
            className="news__list__item__content__btn"
            onClick={() => showData(i)}
          >
            閱讀更多
        </button>
        </Link>
      </h5>
    </div>
  )
};
