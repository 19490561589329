import React from 'react';
import { Helmet } from "react-helmet";

import "./_cover.scss";

export default function Header() {
  return (
    <section className="cover">
      <Helmet>
        <meta name="description" content='久泰營造專營住宅大樓、高層住宅建築工程案承攬，累積數十年的營造經歷，更有整合土木工程、機電工程等各個專業協力廠商的工程實績。工程實績包含葛里法系列建築，如葛里法五世、葛里法等經典豪宅建築。' />
      </Helmet>
      <span
        className='cover__background__img'
        style={{
          backgroundImage: `linear-gradient(
                to right bottom, rgba(0, 0, 0, .80), rgba(0, 0, 0, .85)),
            url(${require(`../assets/img/home.jpg`)})`
        }}
      >
      </span>
      <div className="cover__text">
        <div className="cover__text--title">
          <h1 className="heading-2 heading-3--light">品質</h1>
          <h1 className="heading-7">Quality</h1>
        </div>
        <span> ∙ </span>
        <div className="cover__text--title">
          <h1 className="heading-2 heading-3--light">堅持</h1>
          <h1 className="heading-7">Persistence</h1>
        </div>
        <span> ∙ </span>
        <div className="cover__text--title">
          <h1 className="heading-2 heading-3--light">誠信</h1>
          <h1 className="heading-7">Integrity</h1>
        </div>
      </div>
    </section>
  );
}
