import { useEffect, useCallback } from 'react';

export default function useScrollEvent(props) {
  //為了在useEffect，消除監聽事件，function需改寫成useCallback
  //https://stackoverflow.com/questions/54569681/cannot-remove-an-event-listener-outside-useeffect
  const isInViewport = useCallback(() => {
    //當style都套用之後，移除監聽事件
    if (!props.isExec)
      window.removeEventListener('scroll', isInViewport);

    for (const elm of props.elmArray) {
      if (!elm.stat && document.querySelector(`.${elm.cls}`)) {
        const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        const { top, height } = document.querySelector(`.${elm.cls}`).getBoundingClientRect();
        if ((top > 0 && top + 100 <= viewPortHeight) || (top < 0 && height + top > 100))
          elm.fun();
      }
    }
  }, [props.elmArray, props.isExec]);

  useEffect(() => {
    window.addEventListener('scroll', isInViewport);

    return () => {
      window.removeEventListener('scroll', isInViewport);
    }
  }, [isInViewport]);


}