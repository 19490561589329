import React, { useState, useEffect } from 'react';

import useScrollEvent from '../components/useScrollEvent';

import svgUrl from "../assets/img/sprite.svg";

import "./_features.scss";

let int;

export default function Feature() {
  const [index, setIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [featureStyle, setFeatureStyle] = useState();
  useScrollEvent({
    elmArray: [
      {
        cls: 'features__wrap',
        stat: featureStyle,
        fun: () =>
          setFeatureStyle({ opacity: '1', top: '0' })
      }
    ],
    // dependence: [featureStyle],
    isExec: !featureStyle
  });

  useEffect(() => {
    const renewWidth = () => {
      if (window.innerWidth >= 768 && screenWidth < 768)
        setScreenWidth(window.innerWidth)
      else if (window.innerWidth < 768 && screenWidth >= 768) {
        setIndex(0);
        setScreenWidth(window.innerWidth);
      }
    }

    window.addEventListener('resize', renewWidth);

    return () => {
      window.removeEventListener('resize', renewWidth);
    }
  }, [screenWidth]);

  useEffect(() => {
    const carousel = () => {
      const features = document.getElementsByClassName("feature");

      if (features.length === 0)
        return;

      if (index + 1 === features.length)
        setIndex(0)
      else
        setIndex(index + 1);
    }

    if (screenWidth < 768)
      int = setTimeout(carousel, 5000)
    else {
      const features = document.getElementsByClassName("feature");
      if (features.length !== 0) {
        for (let i = 0; i < features.length; i++) {
          features[i].style.opacity = "1";
        }
      }
    }

    return () => {
      clearTimeout(int);
    }
  }, [index, screenWidth]);

  const Icon = props => (
    <svg className='feature__icon'>
      <use xlinkHref={`${svgUrl}#icon-${props.icon}`} />
    </svg>
  )

  const stopCount = idx => {
    clearTimeout(int);
    setIndex(idx);
  }

  return (
    <section className="features">
      <div className="features__wrap" style={featureStyle}>
        <div
          className="feature"
          style={{
            opacity: (index === 0 || screenWidth >= 768) ? '1' : '0',
            transition: "opacity 1s"
          }}
        >
          <Icon icon='users' />
          <h4 className="feature__heading heading-4 heading-4--dark">
            專業及經驗
          </h4>
          <p className="feature__text heading-6 heading-5--dark">
            久泰營造成立於中華民國80年，專營住宅大樓、高層住宅建築的營造建案。
          </p>
        </div>
        <div
          className="feature"
          style={{
            opacity: (index === 1 || screenWidth >= 768) ? '1' : '0',
            transition: "opacity 1s"
          }}
        >
          <Icon icon='license' />
          <h4 className="feature__heading heading-4 heading-4--dark">
            資本額
          </h4>
          <p className="feature__text heading-6 heading-5--dark">
            依106年7月4日申報資料，久泰營造實收資本額為新台幣陸仟萬元。
          </p>
        </div>
        <div
          className="feature"
          style={{
            opacity: (index === 2 || screenWidth >= 768) ? '1' : '0',
            transition: "opacity 1s"
          }}
        >
          <Icon icon='thumbs-up' />
          <h4 className="feature__heading heading-4 heading-4--dark">
            甲級營造廠
          </h4>
          <p className="feature__text heading-6 heading-5--dark">
            久泰營造自民國90年代初起即通過甲等營造業認證，逾20年來未曾間斷。
          </p>
        </div>
        <div
          className="feature"
          style={{
            opacity: (index === 3 || screenWidth >= 768) ? '1' : '0',
            transition: "opacity 1s"
          }}
        >
          <Icon icon='diamond' />
          <h4 className="feature__heading heading-4 heading-4--dark">
            葛里法建築系列
          </h4>
          <p className="feature__text heading-6 heading-5--dark">
            知名豪宅建築ー葛里法系列的永久承攬營造廠，以細膩工法實踐葛里法的設計。
          </p>
        </div>
        <div
          className="feature"
          style={{
            opacity: (index === 4 || screenWidth >= 768) ? '1' : '0',
            transition: "opacity 1s"
          }}
        >
          <Icon icon='apartment' />
          <h4 className="feature__heading heading-4 heading-4--dark">
            高層建築工程案
          </h4>
          <p className="feature__text heading-6 heading-5--dark">
            承攬統包葛里法五世建案於民國103年峻工，工程造價逾新台幣壹拾壹億元。
          </p>
        </div>
        <div
          className="feature"
          style={{
            opacity: (index === 5 || screenWidth >= 768) ? '1' : '0',
            transition: "opacity 1s"
          }}
        >
          <Icon icon='heart' />
          <h4 className="feature__heading heading-4 heading-4--dark">
            久泰精神
          </h4>
          <p className="feature__text heading-6 heading-5--dark">
            工程品質，優良工法，與商業誠信是久泰營造經營數十年來的不變核心價值。
          </p>
        </div>
      </div>
      <div className="features--circle">
        <div
          className="features--circle--1 circles"
          style={{
            backgroundColor: (index === 0) ? '#000' : '#888888',
            transition: "background-color 1s"
          }}
          onClick={() => stopCount(0)}
        >

        </div>
        <div
          className="features--circle--2 circles"
          style={{
            backgroundColor: (index === 1) ? '#000' : '#888888',
            transition: "background-color 1s"
          }}
          onClick={() => stopCount(1)}
        >

        </div>
        <div
          className="features--circle--3 circles"
          style={{
            backgroundColor: (index === 2) ? '#000' : '#888888',
            transition: "background-color 1s"
          }}
          onClick={() => stopCount(2)}
        >

        </div>
        <div
          className="features--circle--4 circles"
          style={{
            backgroundColor: (index === 3) ? '#000' : '#888888',
            transition: "background-color 1s"
          }}
          onClick={() => stopCount(3)}
        >

        </div>
        <div
          className="features--circle--5 circles"
          style={{
            backgroundColor: (index === 4) ? '#000' : '#888888',
            transition: "background-color 1s"
          }}
          onClick={() => stopCount(4)}
        >

        </div>
        <div
          className="features--circle--6 circles"
          style={{
            backgroundColor: (index === 5) ? '#000' : '#888888',
            transition: "background-color 1s"
          }}
          onClick={() => stopCount(5)}
        >

        </div>
      </div>
    </section>
  );
}
