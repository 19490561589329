import React, { useState } from 'react';
import Modal from 'react-modal';

import svgUrl from "../assets/img/sprite.svg";
import './_GalleryModal.scss';

export default function GalleryModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [data, setData] = useState('');
  const closeModal = () => {
    setModalIsOpen(false);
  }

  const clostBtn =
    <svg
      className='modal__header--btn'
      onClick={closeModal}
    >
      <use xlinkHref={`${svgUrl}#lnr-cross-circle`} />
    </svg>

  const customStyles = {
    content: {
      top: '10%',
      left: '10%',
      right: '10%',
      bottom: '10%',
      overflow: 'none',
      padding: '0',
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    },
    overlay: {
      zIndex: '1000'
    }
  }

  //Portals does't support server-side-render, so we have to return '' for prerender.
  const modalDOM =
    !data ?
      '' :
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          className="modal__content"
        >
          {data}
        </div>
      </Modal>

  return [modalDOM, setData, setModalIsOpen, clostBtn];
}
