import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

import dataList from '../assets/data/newsData.json';
import coverNews from './coverNews';
import NewsDetail from './NewsDetail.js';
// import useScrollEvent from '../components/useScrollEvent';

import "./_newsMain.scss";

function calculateFontNumber() {
  let fonts;
  if (window.innerWidth < 960)
    fonts = 71
  else if (window.innerWidth < 1200)
    fonts = 30
  else if (window.innerWidth < 1360)
    fonts = 45
  else
    fonts = 72;

  return fonts;
}

export default function NewsMain(props) {
  const [fontNumber, setFontNumber] = useState(calculateFontNumber());

  useEffect(() => {
    const updateFontNumber = () => {
      setFontNumber(calculateFontNumber());
    }

    window.addEventListener('resize', updateFontNumber, false);

    return () => {
      window.removeEventListener('resize', updateFontNumber, false);
    }
  }, []);

  const datas = dataList;
  const [showData, NewsDetailDOM] = NewsDetail({ data: datas.df, fontNumber: fontNumber, ...props });
  const newsList = [];
  //exclude first news
  for (let i = 1; i < datas.df.length; i++) {
    newsList.push(
      coverNews(i, datas.df[i], showData, fontNumber)
    );
    if (i === 4)
      break;
  }

  const NewsMainDOM = (
    <section id="news" className="news">
      <h2 className="news__title heading-2 heading-2--dark">營建快訊</h2>
      <article className="news__story mb-lg">
        <div className="news__story__img">
          <LazyLoad offset={200}>
            <img
              src={require(`../assets/img/${datas.df[0].img[0]}.jpg`)}
              aria-hidden
              alt={datas.df[0].alt[0]}
            >
            </img>
          </LazyLoad>
        </div>
        <div className="news__story__article">
          <p className="news__story__article__date heading-6">
            {datas.df[0].date}
          </p>
          <h2 className="news__story__article__title heading-4 heading-4--dark">
            {datas.df[0].title}
          </h2>
          <div className="news__story__article__content heading-6 heading-5--dark">
            {datas.df[0].content.substring(0, fontNumber).replace(/>/g, '\n') + '...'}
            <Link to={`news/${datas.df[0].date.replace(/-/g, '/')}`}>
              <button
                className="news__story__article__content__btn"
                onClick={() => showData(0)}
              >
                閱讀更多
            </button>
            </Link>
          </div>
        </div>

      </article>
      <section className="news__list">
        {newsList}
      </section>
    </section>
  );

  return [NewsMainDOM, NewsDetailDOM];
}
