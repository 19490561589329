import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import LazyLoad from 'react-lazyload';

export default class Map extends React.Component {
  render() {

    const GoogleMapComponent = withScriptjs(withGoogleMap((props) =>
      <GoogleMap
        defaultZoom={18}
        defaultCenter={{ lat: 25.019481, lng: 121.298967 }}
      >
        {props.isMarkerShown && <Marker position={{ lat: 25.019481, lng: 121.298967 }} />}
      </GoogleMap>
    ));

    return (
      <LazyLoad>
        <GoogleMapComponent
          isMarkerShown
          googleMapURL="https://maps.google.com/maps/api/js?key=AIzaSyAYpfvi1IA0F7qkpisZ5s5jO0-OCmAM5JU"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </LazyLoad>
    )
  }
}
