import React, { useState, useEffect } from 'react';
import ReactMarkdown from "react-markdown";
import LazyLoad from 'react-lazyload';

import svgUrl from "../assets/img/sprite.svg";
import datas from '../assets/data/projectsData.json';
import useScrollEvent from '../components/useScrollEvent';

import "./_projectMain.scss";

export default function ProjectsMain() {
  const [index, setIndex] = useState(0);
  const [yearStyle, setYearStyle] = useState('20px');
  const [coverTextStyle, setCoverTextStyle] = useState();
  const [cardStyle, setCardStyle] = useState();
  const [tagName, setTagName] = useState('spec');
  const [cardsDOM, setCardsDOM] = useState([]);
  const [circlesDOM, setCirclesDOM] = useState();
  const [touchStartPosition, setTouchStartPosition] = useState({ x: '', y: '' });
  useScrollEvent({
    elmArray: [
      {
        cls: 'projects__cover__header',
        stat: coverTextStyle,
        fun: () =>
          setCoverTextStyle({ opacity: '1', top: '0' })
      },
      {
        cls: 'projects__cards',
        stat: cardStyle,
        fun: () =>
          setCardStyle({ opacity: '1' })
      }
    ],
    isExec: !coverTextStyle || !cardStyle
  });

  useEffect(() => {
    const tmp = datas.df.map((project, idx) =>
      <div
        key={idx}
        className="projects__cards__content"
        style={{
          left: (index === idx) ? '0' :
            (index > idx) ? '-100%' : '100%',
          //when tagName is updating, dom will re-render
          //and user will see the card that is on the outside of the left side, 
          //moving to the outside on the right side, so we hide it by opacity.
          opacity: (index === idx) ? '1' : '0',
          position: (index === idx) ? 'relative' : 'absolute'
        }}
      >
        <div className="projects__cards__content__img">
          <LazyLoad offset={200}>
            <img
              src={require(`../assets/img/${
                project['detail']
                  .filter($0 => $0.name === tagName)
                  .map($0 => $0.img)
                  .join()
                ||
                //如果沒有該tag，則顯示第一個tag
                project['detail']
                  .map($0 => $0.img)[0]
                }.jpg`)}
              alt={project['detail']
                .filter($0 => $0.name === tagName)
                .map($0 => $0.alt)
                .join()
                ||
                //如果沒有該tag，則顯示第一個tag
                project['detail']
                  .map($0 => $0.alt)[0]}
            />
          </LazyLoad>
        </div>
        <div className="projects__cards__content__text">
          <div className="projects__cards__content__text--title">
            <ReactMarkdown
              source={project.title}
              escapeHtml={false}
            />
            {
              project['href'] ?
                <span>
                  <a
                    href={project['href']}
                    target='_blank'
                    rel="noopener noreferrer"
                  >
                    <Icon icon='link' />
                    官方網站
                  </a>
                </span>
                : ''
            }
          </div>

          <div className="projects__cards__content__text--content">
            <ReactMarkdown
              source={
                project['detail']
                  .filter($0 => $0.name === tagName)
                  .map($0 => $0.text)
                  .join()
                ||
                //如果沒有該tag，則顯示第一個tag
                project['detail']
                  .map($0 => $0.text)[0]
              }
              escapeHtml={false}
            />
          </div>
        </div>
      </div>
    );
    setCardsDOM(tmp);
  }, [tagName, index]);

  useEffect(() => {
    const len = datas.df.length + 2;
    const tmp = [];
    for (let i = 0; i < len; i++) {
      tmp.push(
        <div
          key={i}
          className="projects__timeline--line--circles"
          style={{
            left: (i >= 3) ? '100%' : `${20 + 30 * i}%`,
            // only show three circles in screen.
            opacity: (i >= 3) ? '0' : '1'
          }}
        >
        </div>
      );
    }

    setCirclesDOM(tmp);
  }, []);

  const Icon = props => (
    <svg
      style={props.style}
      className={props.className}
      onClick={props.click}
    >
      <use xlinkHref={`${svgUrl}#lnr-${props.icon}`} />
    </svg>
  )

  const increment = () => {
    if (index + 1 === datas.df.length)
      return;

    const newCardsDOM = [...cardsDOM];
    newCardsDOM[index] = React.cloneElement(newCardsDOM[index], {
      style: {
        left: '-100%',
        opacity: '0',
        position: 'absolute'
      }
    });
    newCardsDOM[index + 1] = React.cloneElement(newCardsDOM[index + 1], {
      style: {
        left: '0',
        opacity: '1',
        position: 'relative'
      }
    });
    const newCirclesDOM = [...circlesDOM];
    //left -> outside on the left side.
    newCirclesDOM[index] = React.cloneElement(newCirclesDOM[index], {
      style: {
        left: '0%',
        opacity: '0'
      }
    });
    //center -> left.
    newCirclesDOM[index + 1] = React.cloneElement(newCirclesDOM[index + 1], {
      style: {
        left: '20%'
      }
    });
    //right -> center.
    newCirclesDOM[index + 2] = React.cloneElement(newCirclesDOM[index + 2], {
      style: {
        left: '50%',
        opacity: (index + 2 >= datas.df.length) ? 0 : '1'
      }
    });
    //outside on the right side -> right.
    newCirclesDOM[index + 3] = React.cloneElement(newCirclesDOM[index + 3], {
      style: {
        left: '80%',
        opacity: (index + 3 >= datas.df.length) ? 0 : '1'
      }
    });
    setYearStyle('50px');
    setIndex(index + 1);
    setCardsDOM(newCardsDOM);
    setCirclesDOM(newCirclesDOM);
  }

  const decrement = () => {
    if (index === 0)
      return;

    const newCardsDOM = [...cardsDOM];
    newCardsDOM[index] = React.cloneElement(newCardsDOM[index], {
      style: {
        left: '100%',
        opacity: '0',
        position: 'absolute'
      }
    });
    newCardsDOM[index - 1] = React.cloneElement(newCardsDOM[index - 1], {
      style: {
        left: '0',
        opacity: '1',
        position: 'relative'
      }
    });
    const newCirclesDOM = [...circlesDOM];
    //left -> center.
    newCirclesDOM[index] = React.cloneElement(newCirclesDOM[index], {
      style: {
        left: '50%',
        opacity: (index >= datas.df.length) ? 0 : '1'
      }
    });
    // outside on the left side -> left.
    newCirclesDOM[index - 1] = React.cloneElement(newCirclesDOM[index - 1], {
      style: {
        left: '20%'
      }
    });
    //center -> rigth.
    newCirclesDOM[index + 1] = React.cloneElement(newCirclesDOM[index + 1], {
      style: {
        left: '80%',
        opacity: (index + 1 >= datas.df.length) ? 0 : '1'
      }
    });
    //right -> outside on the right side.
    newCirclesDOM[index + 2] = React.cloneElement(newCirclesDOM[index + 2], {
      style: {
        left: '100%',
        opacity: '0'
      }
    });

    setYearStyle('50px');
    setIndex(index - 1);
    setCardsDOM(newCardsDOM);
    setCirclesDOM(newCirclesDOM);
  }

  const touchStart = e => {
    const { clientX, clientY } = e.touches[0];
    setTouchStartPosition({
      x: clientX,
      y: clientY
    });
  }

  const touchEnd = e => {
    const deltaX = e.changedTouches[0].clientX - touchStartPosition.x;
    const deltaY = e.changedTouches[0].clientY - touchStartPosition.y;
    if (deltaX > 50 && deltaY < deltaX && deltaY < 30)
      decrement()
    else if (deltaX < -50 && deltaY < -deltaX && deltaY < 30)
      increment();
  }
  //bouncing effect
  let int;
  if (yearStyle !== '20px')
    int = setTimeout(() => {
      setYearStyle('20px')
    }, 500)
  else
    clearTimeout(int);

  return (
    <section id="projects" className="projects">
      <LazyLoad>
        <div
          className="projects__cover"
          style={{
            background: `
                linear-gradient(
                  to right bottom, rgba(0, 0, 0, .80), rgba(0, 0, 0, .80)),
                url(${require(`../assets/img/projects-00b.png`)}),
                rgba(17, 17, 17, 1)`,
            backgroundSize: `
                auto,
                cover,
                auto`,
            backgroundRepeat: `
                repeat,
                no-repeat,
                repeat`,
            backgroundPosition: `
                center,
                center,
                center`
          }}
        >
          <div className="projects__cover__header" style={coverTextStyle}>
            <p className="projects__cover__header--title heading-2 heading-2--dark">
              工程實績
              </p>
            <p className="projects__cover__header--content heading-6">
              久泰營造專營住宅大樓、高層住宅建築工程案承攬，累積數十年的營造經歷，更有整合土木工程、機電工程等各個專業協力廠商的工程實績。工程實績包含葛里法系列建築，如葛里法五世、葛里法等經典豪宅建築。
              </p>
          </div>
        </div>
      </LazyLoad>

      <div className="projects__timeline">
        <Icon
          icon='chevron-left'
          className='projects__timeline--nav'
          click={decrement}
        />

        <div className="projects__timeline--line">
          {circlesDOM}
          <div
            className="projects__timeline--line--year heading-5"
            style={{ bottom: yearStyle }}
          >
            {datas.df[index].year}
          </div>
        </div>

        <Icon
          icon='chevron-right'
          className='projects__timeline--nav'
          click={increment}
        />
      </div>
      <div
        className="projects__cards"
        style={{ ...cardStyle }}
      >
        {
          cardsDOM.map(card =>
            React.cloneElement(card, {
              onTouchStart: touchStart,
              onTouchEnd: touchEnd
            })
          )
        }
        <div
          className="projects__cards__sidebar"
        >
          <div className="projects__cards__sidebar__tags">
            {
              datas.df[index]['detail'].map(($0, idx) => {
                const { name } = $0;
                return (
                  <span
                    key={idx}
                    onClick={() => setTagName(name)}
                    style={{
                      color:
                        (
                          tagName === name ||
                          (
                            //If there is no the current tagName in this project, it will show the first detail.
                            datas.df[index]['detail'].filter($0 => $0.name === tagName).length === 0 &&
                            datas.df[index]['detail'].map($0 => $0.name)[0] === name)
                        ) ? '#000' : '',
                      fontWeight:
                        (
                          tagName === name ||
                          (
                            //If there is no the current tagName in this project, it will show the first detail.
                            datas.df[index]['detail'].filter($0 => $0.name === tagName).length === 0 &&
                            datas.df[index]['detail'].map($0 => $0.name)[0] === name)
                        ) ? 'bold' : '',
                    }}
                  >
                    {name.toUpperCase()}
                  </span>
                )
              })
            }
          </div>
          <div
            className="projects__cards__sidebar__links"
          >
            {
              (datas.df[index]['href']) ?
                <span>
                  <a
                    href={datas.df[index]['href']}
                    target='_blank'
                    rel="noopener noreferrer"
                  >
                    <Icon icon='link' />
                    官方網站
                  </a>
                </span>
                : ''
            }
          </div>
        </div>
      </div>
    </section>
  );
}
