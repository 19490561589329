import React from 'react';

import Map from './Map';

import "./_contact.scss";

export default function Contact() {

  return (
    <section id="contact" className="contact">
      <div className="contact__text">
        <h2
          className="heading-2 heading-2--dark contact__text--title"
        >
          聯絡我們
        </h2>
        <div className="contact__text--information">
          <div className="heading-5 heading-5--dark contact__text--information--address">
            <b>
              Address:
            </b>
            桃園市新埔八街31號2樓
          </div>
          <div className="heading-5 heading-5--dark contact__text--information--phone">
            <b>
              Phone:
            </b>
            (03) 346 6789
          </div>
          <div className="heading-5 heading-5--dark contact__text--information--fax">
            <b>
              Fax:
            </b>
            (03) 317 8356
          </div>
        </div>
      </div>
      <div className="contact__map">
        <Map />
      </div>
    </section>
  );
}
