import React from 'react';
import LazyLoad from 'react-lazyload';

import DescriptionModal from './DescriptionModal';

import datas from '../assets/data/descriptionData.json'

import "./_description.scss"

export default function Description() {
  const [modal, setModalIsOpen, setData] = DescriptionModal();
  const showData = data => {
    const width = window.innerWidth;
    const condition = (width < 768 && data['img-phone']);
    let imgPath;
    if (condition)
      imgPath = data['img-phone'];
    else
      imgPath = data['img-pc'];
    //如果有針對手機的照片，則不限制高度，由外層div出現滾輪顯示
    const img =
      <>
        <div
          className="descriptionModal__content"
          style={{
            height: window.innerHeight * 0.9 * 0.9
          }}
        >
          <LazyLoad>
            <img
              src={require(`../assets/img/${imgPath}.jpg`)}
              aria-hidden
              alt={data.alt}
              style={{
                maxHeight: (!condition) ? window.innerHeight * 0.9 * 0.7 : '',
                maxWidth: (!condition) ? window.innerWidth * 0.9 * 0.9 : window.innerWidth * 0.9 * 0.7
              }}
            >
            </img>
          </LazyLoad>
        </div>
        <p>{data.title}</p>
      </>
    setData(img);
    setModalIsOpen(true);
  }

  const btnList = datas.df.map((data, index) => (
    <button
      key={index}
      className="description__button--item btn"
      onClick={() => showData(data)}
    >
      {data.title}
    </button>
  ))

  return (
    <section className="description">
      <header className="description__text heading-6">
        久泰營造成立於1991年，近30年的時間，只求好還要更好。建造品質專業、嚴謹，堅持重視每一個細節。
        我們將「每一階段品質之達成，皆為提升下一階段品質之基礎」做為基本信念。
      </header>
      <section className="description__button">
        {btnList}
      </section>
      {modal}
    </section>
  );
}
