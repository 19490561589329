import React from 'react';
import { Route, withRouter } from 'react-router-dom';

import Header from "./Header/Header";
import Cover from "./Cover/Cover";
import Features from "./Features/Features";
import ProjectsMain from "./Projects/ProjectsMain";
import Gallery from "./Gallery/Gallery";
import NewsMain from "./News/NewsMain";
import AboutMain from "./About/AboutMain";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";

import "./_app.scss";

function App(props) {
  const [NewsMainDOM, NewsDetailDOM] = NewsMain(props);

  function Master() {
    return (
      <div className="container">
        <Header />
        <Cover />
        <Features />
        <ProjectsMain />
        <Gallery />
        {NewsMainDOM}
        <AboutMain />
        <Contact />
        <Footer />
      </div>
    );
  }

  function News() {
    return (
      <div className="container2">
        <Header isNews={true} />
        {NewsDetailDOM}
        <Footer />
      </div>
    );
  }

  return (
    <>
      <Route
        exact
        path="/"
        component={Master}
      />
      <Route
        path='/news'
        component={News}
      />
    </>
  );
}
//withRouter is HOC
export default withRouter(App);