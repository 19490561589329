import React from 'react';

import "./_footer.scss";

export default function Footer() {

  return (
    <footer className="footer heading-5--dark">
      © 2019 JT Building Incorporation
    </footer>
  );
}
