import React, { useState, useEffect, useRef } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import svgUrl from "../assets/img/sprite.svg";
import photo from "../assets/img/logo.png";

import "./_header.scss";

export default function Header(props) {
  const [svgId, setSvgId] = useState('lnr-menu');
  const [style, setStyle] = useState({ opacity: (window.pageYOffset === 0) ? '0' : '1' });
  const [currentY, setCurrentY] = useState(0);
  const headerRef = useRef(null);
  useEffect(() => {
    const showBackButtonAndHeader = () => {
      if (window.pageYOffset === 0)
        setStyle({
          opacity: '0'
        })
      else
        setStyle({
          opacity: '1'
        });

      const headerDOM = document.querySelector('.header');
      if (currentY <= window.pageYOffset &&
        window.pageYOffset > headerDOM.offsetHeight + 5) {
        headerDOM.style.top = '-100%';
        headerDOM.style.position = 'relative';
      } else {
        headerDOM.style.top = '0';
        headerDOM.style.position = 'fixed';
      }

      setCurrentY(window.pageYOffset);

    }

    window.addEventListener("scroll", showBackButtonAndHeader, false);

    return () => {
      window.removeEventListener("scroll", showBackButtonAndHeader, false);
    }
  }, [style, currentY]);

  const changeSvgId = () => {
    if (svgId === 'lnr-menu')
      setSvgId('lnr-cross')
    else
      setSvgId('lnr-menu');
  }

  const cancelChecked = (isChangeSvg, target, isBackHomePage) => {
    const checkbox = document.getElementById('headerControl');
    checkbox.checked = false;
    smoothscroll.polyfill();
    if (isBackHomePage)
      window.location.assign('/#news')
    else
      document.getElementById(target).scrollIntoView({ behavior: 'smooth' });

    if (isChangeSvg && window.innerWidth < 768) {
      const headerDOM = document.querySelector('.header');
      headerDOM.style.top = '-100%';
      headerDOM.style.position = 'relative';
      changeSvgId();
    }
  }

  return (
    <header className="header" id="header" ref={headerRef}>
      <input
        type="checkbox"
        id="headerControl"
      >
      </input>
      <div className="header__logo">
        <a href="/">
          <img
            src={photo}
            alt="久泰封面照片"
            className="header__logo--img"
          />
        </a>
      </div>
      <nav className="header__nav">
        <ul className="header__nav__list">
          {
            props.isNews ?
              <li
                className="header__nav__list--item"
                onClick={() => cancelChecked(true, 'news__list', true)}
              >
                回久泰首頁
            </li>
              :
              <>
                <li
                  className="header__nav__list--item"
                  onClick={() => cancelChecked(true, 'projects', false)}
                >
                  工程實績
              </li>
                <li
                  className="header__nav__list--item"
                  onClick={() => cancelChecked(true, 'news', false)}
                >
                  營建快訊
              </li>
                <li
                  className="header__nav__list--item"
                  onClick={() => cancelChecked(true, 'about', false)}
                >
                  關於久泰
              </li>
                <li
                  className="header__nav__list--item"
                  onClick={() => cancelChecked(true, 'contact', false)}
                >
                  聯絡我們
              </li>
              </>
          }
        </ul>
      </nav>
      <label htmlFor="headerControl">
        <svg
          className='header__line'
          onClick={changeSvgId}
        >
          <use xlinkHref={`${svgUrl}#${svgId}`} />
        </svg>
      </label>
      <span
        className="header__back"
        onClick={() => cancelChecked(false, 'header')}
        style={style}
      >
        <svg>
          <use xlinkHref={`${svgUrl}#lnr-chevron-up-circle`} />
        </svg>
      </span>
    </header>
  );
}
