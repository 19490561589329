import React, { useState } from 'react';
import Modal from 'react-modal';

import svgUrl from "../assets/img/sprite.svg";

import './_DescriptionModal.scss';

export default function DescriptionModal() {
  const [data, setData] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const customStyles = {
    content :{
      top                   : '5%',
      left                  : '5%',
      right                 : '5%',
      bottom                : '5%',
      overflow              : 'none',
      padding               : '0'
    },
    overlay :{
      zIndex                : '1000'
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const modal =
    <Modal
      isOpen={modalIsOpen}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="descriptionModal">
        <div className="descriptionModal__header">
          <svg
            className='descriptionModal__header--btn'
            onClick={closeModal}
          >
            <use xlinkHref={`${svgUrl}#lnr-cross-circle`} />
          </svg>
        </div>
        {data}
      </div>
    </Modal>;

  return [modal, setModalIsOpen, setData];
}
