import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";

import './_NewsDetail.scss';

let prevLocation = {};

export default function NewsDetail(props) {
  const allNews = props.data;
  const [data, setData] = useState(() => {
    const path = props.location.pathname.replace(/\//g, '-').split('news-');
    const currentIndex = allNews.findIndex(news => news.date === path[1]);
    if (currentIndex !== -1)
      return allNews[currentIndex]
    else
      return allNews[0];
  });
  const [route, setRoute] = useState();
  //因為父層會隨著window resize更新state，造成子層重新渲染，
  //試著使用useMemo避免子層重新渲染，結果成功了，但dom inline-style仍被清掉，
  //因此暫時先用useLayoutEffect在畫面render前在一次將圖片加上style//
  //不可使用useEffect，因useEffect, render, useLayoutEffect三者執行順序為下
  //useLayoutEffect-> render -> useEffect，因此使用useEffect會造成圖片閃爍一下
  useLayoutEffect(() => {
    const firstImg = document.querySelectorAll('.newsdetail__content--img--main--imgs')[0];
    if (firstImg) {
      firstImg.style.display = 'block';
      firstImg.style.position = 'relative';
    }
  }, [props.fontNumber]);

  useEffect(() => {
    const firstImg = document.querySelectorAll('.newsdetail__content--img--main--imgs')[0];
    if (firstImg) {
      firstImg.style.display = 'block';
      firstImg.style.position = 'relative';
    }
  }, [route, data]);

  useEffect(() => {
    const listen = props.history.listen(location => {
      setRoute(location.pathname);
      //跳轉至新聞頁面，從最上方開始
      const pathChanged = prevLocation.pathname !== location.pathname;
      const hashChanged = prevLocation.hash !== location.hash;
      if (pathChanged || hashChanged)
        window.scrollTo(0, 0);

      prevLocation = location;
    });

    return () => {
      listen();
    }
  }, [props.history]);

  const handleChange = e => {
    const targetIndex = allNews.findIndex(item => item.title === e.target.value);
    props.history.push(`/news/${allNews[targetIndex].date.replace(/-/g, '/')}`);
    showData(targetIndex);
  }
  const showData = idx => {
    setData(allNews[idx]);
  }

  function changeImg(k) {
    const imgs = document.querySelectorAll('.newsdetail__content--img--main--imgs');
    const target = document.getElementById(k);
    for (const img of imgs) {
      if (target === img) {
        img.style.display = 'block';
        img.style.position = 'relative';
      } else {
        img.style.display = 'none';
        img.style.position = 'absolute';
      }
    }
  }
  //for width >= 768 px
  const titleList1 = allNews.map((news, idx) =>
    <Link
      key={idx}
      to={`/news/${news.date.replace(/-/g, '/')}`}
    >
      <ul
        className="newsdetail__newslist--items1"
        onClick={() => showData(idx)}
        style={{
          color: (news.title === data.title) ? '#000' : '',
          fontWeight: (news.title === data.title) ? 'bold' : '',
        }}
      >
        {news.title}
      </ul>
    </Link>
  );
  //for width < 768 px
  const titleList2 =
    <select
      value={data.title}
      className="newsdetail__newslist--items2"
      onChange={handleChange}
    >
      {allNews.map((news, idx) =>
        <option
          key={idx}
          value={news.title}
        >
          {news.title}
        </option>
      )}
    </select>

  const NewsDetailDOM = (
    <div className="newsdetail">
      <div className="newsdetail__newslist">
        {titleList1}
        {titleList2}
      </div>
      <div
        className="newsdetail__content"
      >
        {
          allNews.map((news, idx) => (
            <Route
              key={idx}
              path={`/news/${news.date.replace(/-/g, '/')}`}
              render={() => (
                <>
                  <Helmet>
                    <title>{news.title}</title>
                    <link rel="canonical" href="https://www.jtbuilding.com.tw/news/2019/06/05" />
                    <meta name="description" content={news.content.replace('>', '').split('。')[0]} />
                  </Helmet>
                  <div className="newsdetail__content--img">
                    <div className="newsdetail__content--img--main">
                      {/* 先全部render出來，透過css控制顯示圖片，避免mobile圖片出現閃爍 */}
                      {news.img.map((img, i) =>
                        <div
                          key={i}
                          id={img}
                          className="newsdetail__content--img--main--imgs"
                        >
                          <img
                            src={require(`../assets/img/${img}.jpg`)}
                            aria-hidden
                            alt={news.alt[i]}
                          >
                          </img>
                        </div>
                      )}
                    </div>
                    {
                      (news.img.length > 1) ?
                        <div className="newsdetail__content--img--others">
                          {
                            news.img.map((img, i) => (
                              <div
                                key={i}
                                className="newsdetail__content--img--others--items"
                              >
                                <img
                                  src={require(`../assets/img/${img}.jpg`)}
                                  aria-hidden
                                  alt={news.alt[i]}
                                  onClick={() => changeImg(img)}
                                >
                                </img>
                              </div>
                            ))
                          }
                        </div> : ''
                    }
                  </div>
                  <div className="newsdetail__content--text">
                    <p
                      className="newsdetail__content--text--date heading-5 heading-5--dark"
                    >
                      Date: {news.date}
                    </p>
                    <h2
                      className="newsdetail__content--text--title heading-3 heading-3--dark"
                    >
                      {news.title}
                    </h2>
                    <ReactMarkdown
                      className="heading-6 heading-5--dark"
                      source={news.content}
                      escapeHtml={false}
                    />
                  </div>
                </>
              )}
            />
          ))
        }
      </div>
    </div>
  );

  return [showData, NewsDetailDOM];
}